@import '../../../index.scss';

.news {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0rem 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.news>div {
    width: 100%;
}*/