@import '../../index.scss';

.footer {
    /*display: flex;
    justify-content: space-between;
    align-items: center;*/
    padding: 0.6rem 0;
    /*border-bottom: 3px solid $brown;*/
}

.footer>.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__copyright {
    color: $brown;
    margin: 0;
    padding: 0;
}

.footer__copyright>span {
    font-weight: bold;
}

.footer__social__container {
    display: flex;
    gap: 10px
}
.footer__social {
    margin-bottom: -10px;
    /*margin-left: 10px;*/
}

.footer__social a {
    margin: 0;
    padding: 0;
    color: $dark;
    font-size: 2rem;
}
.footer__social a:hover {
    color: $brown;
    transition: all 0.3s ease;
}

.footer__social__poweredBy {
    color: $brown;
    text-decoration: none;
}
.footer__social__poweredBy:hover {
    text-decoration: underline;
    transition: all 0.3s ease;
}



