@import '../../../index.scss';

.staff {
    max-width: 902px;
    margin: 2rem auto;
    padding: 1rem 0.5rem;
    /*background: $blue2;
    border-radius: 10px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    /*gap: 1rem;*/


    &__team {
        text-align: center;

        & h4 {
            margin-bottom: 2rem;
            margin-top: 1rem;
            text-transform: uppercase;
            /*text-shadow: 1px 1px 1px white; */
        }
        // Estilos para cada miembro del equipo
        &__member {
            /*background: white;*/
            border: 3px solid #1D957F;
            border-radius: 10px;
            padding: 15px 25px;
            text-align: center;
            max-width: 250px;
            min-height: 300px;
            margin-bottom: 0.75rem;
            margin-right: .75rem;
            opacity: 0; /* Para animar */
            filter: drop-shadow(0px 12px 12px rgba(50, 50, 71, 0.08)) drop-shadow(0px 16px 24px rgba(50, 50, 71, 0.08));

            @include tablet {
                margin-right: 0;
                margin-bottom: 1rem;
                flex-direction: row;
            }

            &__picture {
                max-width: 80%;
                border-radius: 50%;
                margin-bottom: 1.5rem;
                &:hover {
                    filter: grayscale(100%);
                    cursor: pointer;
                }
            }
            &__name {
                font-size: 1rem;
                color: #081E29;
                margin: 0.5rem 0;
            }
            &__role {
                color: #1D957F;
            }
        }
    }
}


.wrapper_team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include tablet {
        flex-direction: column;
        gap: .25rem;
    }
}